import { useEffect, useState } from "react";
import SYBR_LOGO from './assets/SYBR_Logo.png'
export default function RedirectPage() {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          window.location.href = "https://sybrtms.com";
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#1d3b7d', overflow: 'hidden' }}>
      <div style={{ maxWidth: '400px', textAlign: 'center', padding: '20px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <img src={SYBR_LOGO} alt="sybr-logo" style={{ height: '150px' }} />
        <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>SYBR.org Has Moved!</h1>
        <p style={{ marginTop: '10px', color: '#555' }}>
          Our website has been relocated to a new domain. You will be redirected to
          <span style={{ fontWeight: 'bold', color: '#007bff' }}> SYBRTMS.com </span> in {countdown} seconds.
        </p>
        <div style={{ marginTop: '20px' }}>
          <button
            onClick={() => (window.location.href = "https://sybrtms.com")}
            style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}
          >
            Go to SYBRTMS.com Now
          </button>
        </div>
      </div>
    </div>
  );
}
